<template>
  <div style="max-width: 1440px; margin: 0 auto;">
    <HeaderComponent @linkClicked="scrollTo"/>
    <HeroComponent />
    <div ref="facesSection" style="margin: 0 30px; margin-bottom: 50px;">
      <div id="faces-header">
        <h2 class="roboto-condensed-medium">Faces</h2>
        <p></p>
      </div>
      <div id="watches-container">
        <WatchItemComponent 
          v-for="watch in watches"
          :key="watch.id"
          :watch="watch"
        />
      </div>
    </div>
    <div ref="aboutSection" style="margin: 0 30px; margin-bottom: 25px;">
      <div style="text-align: center;">
        <h2 class="roboto-condensed-medium">Coming September 2024</h2>
        <p>Made with ❤️ in NYC 🗽</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import HeaderComponent from './components/HeaderComponent.vue'
import HeroComponent from './components/HeroComponent.vue'
import WatchItemComponent from './components/WatchItemComponent.vue'
import { data } from './data/data.js'
import { ref } from 'vue'

const watches = data;
const facesSection = ref(null);
const aboutSection = ref(null);

const scrollTo = (section) => {
  if (section === 'Faces' && facesSection.value)
    facesSection.value.scrollIntoView({ behavior: 'smooth' });
  else if (section === 'About' && aboutSection.value)
    aboutSection.value.scrollIntoView({ behavior: 'smooth' });
};

</script>

<style scoped>
  h2 {
    font-size: 45px;
  }

  #watches-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px 0;
  }

  #watches-container > * {
    box-sizing: border-box;
  }

  #faces-header {
    text-align: center;
    display: none;
  }

  @media (min-width: 992px) {
    #watches-container {
      grid-template-columns: repeat(3, 1fr);
      gap: 60px 24px;
    }

    #faces-header {
      display: initial;
    }
  }
</style>
